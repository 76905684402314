import React from "react";
import { Link } from "react-router-dom";
// core
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import GridCondutor from "components/Grid/GridCondutor.js";
import GridCondutorV2 from 'components/Grid/GridCondutorV2';
import LoadingGrid from "components/Loading/LoadingGrid.js";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import {msg} from 'services/msg.js';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import MySnackbarErro from 'components/Uteis/MySnackbarErro.js';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
import MeuPlanoAtual from 'components/Uteis/MeuPlanoAtual';
import Button from 'components/CustomButtons/Button';
 
export default class Condutores extends React.Component {
  
  constructor(props) {
    super(props);

    checaLiberacaoMenu(props);

    this.state  = {
      condutores: [],
      loadingGrid: true,
      isErro: false,
      condutorFake: []
    };
  } 
  componentDidMount(){
    
    this.carregar();
  }

  carregar = async () => {
    this.setState({isErro: false});
    await api.get(serverApi.url + serverApi.versao + '/Habilitacao/Habilitacoes').then(response => {
      if(response.data.length > 0) {
        this.setState({
          condutores: response.data
        });  
      } else {
        let objHabilitacaoFake = [];
        objHabilitacaoFake.push({
          Ativo: true, 
          Categoria: null,
          Cpf: '???????',
          DataEmissao: null,
          DataVencimento: null,
          Guid: null,
          Id: 0,
          LocalRegistro: null,
          Nome: "NOME DO CONDUTOR",
          NumeroCnh: "????????",
          QtdeConsultas: 0,
          TotalPontos: 0,
          Chips: [{ 
            Color: "primary",
            Label: "Olá ! Você ainda não fez o cadastro de sua CNH no App. Faça agora e descubra  se possui alguma multa",
            Style: { backgroundColor: "#3F51B5", color: "white" } }]
        });
        this.setState({
          condutorFake: objHabilitacaoFake
        });
      }
    })
    .catch(error => {
      console.error(error);
      this.setState({isErro: true}); 
    })
    .finally(() => {   
      this.setState({
        loadingGrid: false
      });   
    });
  }

  clickCadastarCNH = () => {
    this.props.history.push({
      pathname: `condutores/cadastrar`
    });
  }
  
  render() {
    return (
      <>
        <LoadingGrid display={this.state.loadingGrid} />
        <div style={{display: (!this.state.loadingGrid ? '' : 'none'), width: '100%'}}>
          <MeuPlanoAtual />
            <GridCondutorV2  display={!this.state.loadingGrid} condutores={this.state.condutores.length > 0 ? this.state.condutores : this.state.condutorFake} />
            {this.state.condutores.length <= 0 ? (
              <GridContainer style={{textAlign: 'center'}}>
                <GridItem xs={12} sm={12} md={12}>
                  <Button type="button" onClick={this.clickCadastarCNH} style={{backgroundColor: '#00acc1', margin: '50px 0'}}>Cadastrar CNH</Button>
                </GridItem>
              </GridContainer>
            ) :
            ('')}
            <Tooltip title="Adicionar nova CNH" aria-label="Adicionar nova CNH" style={{position: 'fixed', bottom: '35px',right: '20px'}}>
              <Link to={"condutores/cadastrar"}>
                <Fab>
                  <AddIcon />
                </Fab>
              </Link>
            </Tooltip>
          </div>
          {/* <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} /> */}
     </>
    );
  }
}