import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import MySnackbarErro from 'components/Uteis/MySnackbarErro.js';
import MySnackbarSuccess from 'components/Uteis/MySnackbarSuccess.js';
import avatar from "assets/img/faces/user-default.png";
import LoadingGrid from "components/Loading/LoadingGrid.js";
import MeuPlanoAtual from 'components/Uteis/MeuPlanoAtual';

//import avatar from "assets/img/faces/marc.jpg";
import InputMask from 'react-input-mask';

import estados from '../../services/estados.json';
import cidades_json from '../../services/cidades.json';
import { truncate } from "fs";

const styles = {
  cardBorderTop: {
    borderTop: "2px #00ACC1 solid"
  },
  formControl: {
    marginTop: 26,
    width: '100%'
  },
  btnInfoPlano: {
    marginTop: 15
  }
};

const useStyles = makeStyles(styles);

export default function PerfilUsuario(props) {

  const [usuario, setUsuario] = useState('');
  const [usuarioGuid, setUsuarioGuid] = useState('');
  const [usuarioNome, setUsuarioNome] = useState('');
  const [usuarioCPF, setUsuarioCPF] = useState('');
  const [usuarioRG, setUsuarioRG] = useState('');
  const [usuarioDataNasc, setUsuarioDataNasc] = useState('');
  const [usuarioTelefone, setUsuarioTelefone] = useState('');
  const [usuarioSexo, setUsuarioSexo] = useState('E');
  const [usuarioEndereco, setUsuarioEndereco] = useState('');
  const [usuarioNumero, setUsuarioNumero] = useState('');
  const [usuarioComplemento, setUsuarioComplemento] = useState('');
  const [usuarioCidade, setUsuarioCidade] = useState('');
  const [usuarioBairro, setUsuarioBairro] = useState('');
  const [usuarioUF, setUsuarioUF] = useState('RJ');
  const [usuarioCidades, setUsuarioCidades] = useState(cidades_json.filter(cidadej => cidadej.Estado == 19));
  const [usuarioCEP, setUsuarioCEP] = useState('');
  
  const [loadingGrid, setLoadingGrid] = useState(true); 
  const [isErro, setIsErro] = useState(false); 
  const [mensagemErro, setMensagemErro] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);


  useEffect(() => {          
    carregarDados();
  },[props] );

  function carregarDados() {
      setLoadingGrid(true);
      
      api.get(serverApi.url + serverApi.versao + '/Usuario/Perfil')
      .then(function (response) {
        //console.log("resposta perfil", response);
        let user = response.data;
        setUsuario(user);
        setUsuarioGuid(user.Guid);
        setUsuarioNome(user.Nome);
        setUsuarioCPF(user.Cpf);
        setUsuarioRG(user.Rg);
        setUsuarioDataNasc(formataExibicaoDataNasc(user.DataNascimento));
        setUsuarioTelefone(user.Telefone);
        if(user.Sexo != null) {
          setUsuarioSexo(user.Sexo);
        }
        setUsuarioEndereco(user.Endereco);
        setUsuarioNumero(user.Numero);
        setUsuarioComplemento(user.Complemento);
        setUsuarioUF(user.UF);

        var estado_selecionado = user.UF;
        var estado = estados.filter(function(item) {
          return (item.Sigla == estado_selecionado) ? item.ID : '';
        });
        let id_estado = estado[0].ID;
        var cidades_filtradas = cidades_json.filter(function(cidade) {
          return cidade.Estado == id_estado;
        });
        setUsuarioCidades(cidades_filtradas);
        setUsuarioCidade(user.Cidade);
        setUsuarioBairro(user.Bairro);
        
        setUsuarioCEP(user.Cep);
      })
      .catch(function (error) {
        console.log(error);
      })
      .finally(() => {   
        setLoadingGrid(false);
      });
  }

  const handleClickShow = () => {
    setOpenSnackbar(true);
  };

  const handleClickClose = () => {
    setOpenSnackbar(false);
  };

  async function handleChangeUser(e) {
    e.preventDefault();

    //FORMATAR A DATA DE NASCIMENTO PARA ENVIO NA API
    let dataNaoFormatada = usuarioDataNasc.split("/");

    if(!validateForm()) {
      return;
    }
    setLoadingGrid(true);
    
    var querystring = require('querystring');
    const data = querystring.stringify({ Nome: usuarioNome, Cpf: usuarioCPF, Telefone: usuarioTelefone, Sexo: usuarioSexo, Endereco: usuarioEndereco, Numero: usuarioNumero, Complemento: usuarioComplemento, Cidade: usuarioCidade, Bairro: usuarioBairro, Uf: usuarioUF, Cep: usuarioCEP, DataNascimento: `${dataNaoFormatada[2]}-${dataNaoFormatada[1]}-${dataNaoFormatada[0]}`, Rg: usuarioRG});

    api.post(serverApi.url + serverApi.versao + '/Usuario/Alterar', data).then(response => {
      setOpenSnackbar(true);
      let user = response.data;
      setUsuario(user);
      setUsuarioGuid(user.Guid);
      setUsuarioNome(user.Nome);
      setUsuarioCPF(user.Cpf);
      setUsuarioTelefone(user.Telefone);
      setUsuarioSexo(user.Sexo);
      setUsuarioEndereco(user.Endereco);
      setUsuarioNumero(user.Numero);
      setUsuarioComplemento(user.Complemento);
      setUsuarioCidade(user.Cidade);
      setUsuarioBairro(user.Bairro);
      setUsuarioUF(user.Uf);
      setUsuarioCEP(user.Cep);
      setUsuarioDataNasc(formataExibicaoDataNasc(user.DataNascimento));
      setUsuarioRG(user.Rg);
    })
    .catch(error => {
      // handle error
      console.error(error);
    })
    .finally( ()=> {
      setLoadingGrid(false);
    });
  }

  const handleChangeSelectSexo = event => {
    setUsuarioSexo(event.target.value);
  };

  const handleChangeSelectUF = event => {
    setUsuarioUF(event.target.value);
    var estado_selecionado = event.target.value;
    var estado = estados.filter(function(item) {
      return (item.Sigla == estado_selecionado) ? item.ID : '';
    });
    let id_estado = estado[0].ID;
    var cidades_filtradas = cidades_json.filter(function(cidade) {
      return cidade.Estado == id_estado;
    });
    setUsuarioCidades(cidades_filtradas);
  };

  const handleChangeSelectCidade = event => {
    setUsuarioCidade(event.target.value);
  };

  function formataExibicaoDataNasc(data) {
    let dataNaoFormatada1 = data.split("T")[0];
    let dataNaoFormatada = dataNaoFormatada1.split("-");
    return `${dataNaoFormatada[2]}/${dataNaoFormatada[1]}/${dataNaoFormatada[0]}`;
  }

  const handleChangeDataNasc = event => {
    let dataNaoFormatada = event.target.value.split("/");
    setUsuarioDataNasc(`${dataNaoFormatada[0]}/${dataNaoFormatada[1]}/${dataNaoFormatada[2]}`);
  };

  function validateForm() {
    if((usuarioSexo.length === 1 && usuarioSexo == 'E')) {
      alert("Todos os campos devem ser preenchidos!");
      return false;
    }
    if (usuarioNome.length === 0 || usuarioCPF.length === 0 || usuarioRG.length === 0 || usuarioDataNasc.length === 0 || usuarioTelefone.length === 0 || usuarioEndereco.length === 0 || usuarioCidade.length === 0 || usuarioBairro.length === 0 || usuarioUF.length === 0 || usuarioCEP.length === 0) {
      alert("Todos os campos devem ser preenchidos!");
      return false;
    }
    return true;
  }


  const classes = useStyles();
  return (
    <div>
      <LoadingGrid display={loadingGrid} />
      <div style={{display: (!loadingGrid && !isErro ? '' : 'none')}}>
        <MeuPlanoAtual />
        <div style={{marginTop: '20px'}}>
          <MySnackbarSuccess showAlert={openSnackbar} mensagem="Dados atualizados!" clickShow={handleClickShow} clickClose={handleClickClose} />
        </div>
        <form onSubmit={handleChangeUser}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card className={classes.cardBorderTop}>
                <CardAvatar profile style={{marginTop: '-20px'}}>
                    <img src={avatar} alt="..." />                
                </CardAvatar>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CustomInput
                        labelText="E-mail"
                        id="email"
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          disabled: true,
                          value: usuario.Email
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Nome"
                        id="nome"
                        required
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          value: usuarioNome,
                          defaultValue: " ",
                          onChange: e => setUsuarioNome(e.target.value),
                          required: true,
                        }}
                        
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputMask mask="999.999.999-99" 
                        maskChar=" "
                        value={usuarioCPF} 
                        onChange={e => setUsuarioCPF(e.target.value.replace(".","").replace(".","").replace("-",""))}
                        required  
                      >
                        {(inputProps) => <TextField {...inputProps} 
                          style={{marginTop : '30px'}}
                          id="cpf"
                          label="CPF"
                          margin="dense"
                          fullWidth
                          InputProps={{
                            /* disabled: true, */
                          }}
                        />}
                      </InputMask>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="RG *"
                        id="rg"
                        required
                        formControlProps={{
                          fullWidth: true
                        }}
                        inputProps={{
                          value: usuarioRG,
                          defaultValue: " ",
                          onChange: e => setUsuarioRG(e.target.value),
                          required: true,
                        }}
                        
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputMask mask="99/99/9999" 
                        maskChar=" "
                        value={usuarioDataNasc} 
                        onChange={handleChangeDataNasc}
                        required  
                      >
                        {(inputProps) => <TextField {...inputProps} 
                          style={{marginTop : '30px'}}
                          id="dataNasc"
                          label="Data de nascimento"
                          margin="dense"
                          fullWidth
                          InputProps={{
                            /* disabled: true, */
                          }}
                        />}
                      </InputMask>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputMask mask="(99) 9999-99999" 
                        maskChar=" "
                        value={usuarioTelefone} 
                        onChange={e => setUsuarioTelefone(e.target.value)}
                        /* onChange={e => setUsuarioTelefone(e.target.value.split("(").join("").split(")").join("").replace(" ","").replace("-",""))} */
                        required  
                      >
                        {(inputProps) => <TextField {...inputProps} 
                          type="tel" 
                          style={{marginTop : '30px'}}
                          id="telefone"
                          label="Telefone"
                          margin="dense"
                          fullWidth
                        />}
                      </InputMask>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl className={classes.formControl}>
                        <InputLabel htmlFor="sexo-native-simple">Sexo *</InputLabel>
                        <Select
                          native
                          value={usuarioSexo}
                          onChange={handleChangeSelectSexo}
                          required
                        >
                          <option value={'E'}>Selecione o sexo</option>
                          <option value={'M'}>Masculino</option>
                          <option value={'F'}>Feminino</option>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Endereço"
                        id="endereco"
                        required
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          value: usuarioEndereco,
                          defaultValue: " ",
                          onChange: e => setUsuarioEndereco(e.target.value),
                          required: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Número"
                        id="numero"
                        required
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          value: usuarioNumero,
                          defaultValue: " ",
                          type: "number",
                          onChange: e => setUsuarioNumero(e.target.value),
                          required: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <CustomInput
                        labelText="Complemento"
                        id="complemento"
                        formControlProps={{
                          fullWidth: true,
                          required: false
                        }}
                        inputProps={{
                          value: usuarioComplemento,
                          defaultValue: " ",
                          type: "text",
                          onChange: e => setUsuarioComplemento(e.target.value)
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                    <FormControl style={{marginTop: 26, width: '100%'}}>
                      <InputLabel htmlFor="uf-native-simple">UF *</InputLabel>
                        <Select
                          native
                          id="uf"
                          value={usuarioUF}
                          onChange={handleChangeSelectUF}
                          required
                        >
                          {estados.map((currentValue, index, arr) => 
                            <option key={index} value={currentValue.Sigla} >{currentValue.Nome}</option>
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <CustomInput
                        labelText="Bairro"
                        id="bairro"
                        required
                        formControlProps={{
                          fullWidth: true,
                          required: true
                        }}
                        inputProps={{
                          value: usuarioBairro,
                          defaultValue: " ",
                          onChange: e => setUsuarioBairro(e.target.value),
                          required: true,
                        }}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                      <FormControl style={{marginTop: 26, width: '100%'}}>
                        <InputLabel htmlFor="uf-native-simple">Cidade *</InputLabel>
                        <Select
                          native
                          id="cidade"
                          value={usuarioCidade}
                          onChange={handleChangeSelectCidade}
                          required
                        >
                          {usuarioCidades.map((currentValue, index, arr) => 
                            <option key={index} value={currentValue.Nome} >{currentValue.Nome}</option>
                          )}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <InputMask mask="99999-999" 
                        maskChar=" "
                        value={usuarioCEP}
                        onChange={e => setUsuarioCEP(e.target.value)}
                        /* onChange={e => setUsuarioCEP(e.target.value.replace("-",""))} */
                        required
                      >
                        {(inputProps) => <TextField {...inputProps} 
                          type="tel" 
                          style={{marginTop : '30px'}}
                          id="cep"
                          label="CEP"
                          margin="dense"
                          fullWidth
                        />}
                      </InputMask>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter>
                  <Button type="submit" color="success">Salvar</Button>
                </CardFooter>
              </Card>
            </GridItem>
            {/* <GridItem xs={12} sm={12} md={4}>
              <Card className={classes.cardBorderTop} profile>
                <CardBody profile>
                  <h2>PLANO</h2>
                  <h4>{usuario.Descricao}</h4>
                  <div className="normal text-muted text-left">
                    <span>Máximo de Veículos: <b>{usuario.MaxVeiculo}</b></span>
                  </div>
                  <div className="normal text-muted text-left">
                    <span>Máximo de Condutores: <b>{usuario.MaxHabilitacao}</b></span>
                  </div>
                  <Button type="button" color="info" href="planos" className={classes.btnInfoPlano}>Detalhes do plano</Button>
                </CardBody>
              </Card>
            </GridItem> */}
          </GridContainer>
        </form>
      </div>
      <MySnackbarErro IsErro={isErro} Mensagem={mensagemErro} />
    </div>
  );
}
