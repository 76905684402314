import React from "react";
// @material-ui/core components
import ViewRecurso from "components/View/ViewRecurso";
import LoadingFull from "components/Loading/LoadingFull";
import api from "services/api.js";
import { serverApi } from "services/constante.js";
import { msg } from "services/msg.js";
import MySnackbarErro from "components/Uteis/MySnackbarErro.js";
import MeuPlanoAtual from "components/Uteis/MeuPlanoAtual";
import checaLiberacaoMenu from "services/checaLiberacaoMenu";

export default class RecursoView extends React.Component {
  constructor(props) {
    super(props);

    checaLiberacaoMenu(props);

    this.state = {
      recursos: [],
      loadingGrid: true,
      isErro: false
    };
  }
  componentDidMount() {
    this.setState({ isErro: false });

    api
      .post(serverApi.url + serverApi.versao + "/Multa/TodosRecursos")
      .then(response => {
        this.setState({ recursos: response.data });
        //console.log(response.data);
      })
      .catch(error => {
        console.error(error);
        this.setState({ isErro: true });
      })
      .finally(() => {
        this.setState({
          loadingGrid: false
        });
      });
  }

  render() {
    return (
      <>
        <LoadingFull display={this.state.loadingGrid} />
        <MeuPlanoAtual />

        <ViewRecurso recursos={this.state.recursos} />
        {/* <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} /> */}
      </>
    );
  }
}
