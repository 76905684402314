import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import ReviewBoleto from 'views/Checkout/ReviewBoleto';
import LoadingGrid from "components/Loading/LoadingGrid.js";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import MySnackbarSuccess from 'components/Uteis/MySnackbarSuccess.js';
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';

const useStyles = makeStyles((theme) => ({
    boxPayment: {
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        padding: 13,
        backgroundColor: '#f9f7f7',
        borderRadius: 5,
    },
    boxPaymentMobile: {
        width: '100%',
        margin: '0 auto',
        textAlign: 'center',
        padding: 13,
        backgroundColor: '#f9f7f7',
        borderRadius: 5,
    },
    titleBoleto: {
        fontSize: 20,
        fontFamily: 'Montserrat,sans-serif'
    },
    descriptionBoleto: {
        color: '#716d6d',
        paddingTop: 8,
        textAlign: 'center',
        fontFamily: 'Montserrat,sans-serif',
        fontSize: 15
    },
    boxCode: {
        width: '80%',
        margin: '0 auto',
    },
    infoCodeOrder: {
        fontSize: 10,
        fontFamily: 'Montserrat,sans-serif',
        color: '#716d6d',
        textAlign: 'center',
    },
    paymentCode: {
        wordWrap: 'break-word'
    },
    appBar: {
        position: 'relative',
    },
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            width: 600,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 5),
    },
    buttons: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    button: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
    buttonCopyToClipboard: {
        marginTop: theme.spacing(3),
        marginLeft: theme.spacing(1),
    },
}));

//const useStyles = makeStyles(styles);

export default function PicPay(props) {
    const classes = useStyles();
    const [items, setItems] = useState([]);
    const [payment, setPayment] = useState(false);
    const [paymentCode, setPaymentCode] = useState("");
    const [paymentLink, setPaymentLink] = useState("");
    const [numPedido, setNumPedido] = useState("");
    const [loadingGrid, setLoadingGrid] = useState(false);
    const [snackbar, setSnackbar] = useState(false);
    const [pe, setPE] = useState([]);// Produtos Extras
    const [guidsPE, setGuidsPE] = useState([]);// Guids Produtos Extras
    const [pesSolo, setPesSolo] = useState([]);// Produtos Extras SEPARADOS

    const matches = useMediaQuery('(max-width:600px)');


    useEffect(() => {          
        carregarDados();
    },[] );

    async function carregarDados() {
        //setLoadingGrid(true);
        let prodExtras = [];
        let id_plano = props.match.params.idplano;
        let id_plano_detalhe = props.match.params.idplanodetalhe;
        
        if (id_plano != undefined && id_plano_detalhe != undefined) {
            var formdata = new FormData();
            formdata.append('function', 'get_plano_by_id_plano_id_plano_detalhe');
            formdata.append('id_plano', id_plano);
            formdata.append('id_plano_detalhe', id_plano_detalhe);

            await api.post(serverApi.url + '/apicdl/plano.php', formdata)
            .then(function (response) {
                //console.log(response);
                let plano = response.data.plano;
                setItems([{Descricao: plano.Descricao, Periodicidade: plano.Periodicidade, Preco: plano.Preco}]);

                //GOOGLE ANALYTICS
                window.gtag('event', 'select_content', {
                    "content_type": "product",
                    "items": [
                    {
                        "id": id_plano + "_" + id_plano_detalhe,
                        "name": plano.Descricao + " Periodicidade: " + plano.Periodicidade,
                        "brand": "Condutor Legal",
                        "category": "Plano",
                        "quantity": 1,
                        "price": parseFloat(plano.Preco)
                    }
                ]});
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {   
                //setLoadingGrid(false);
            });
        } else {
            let guids = "";
            let quant = "";
            if(props.location.state != undefined) {
                guids = props.location.state.guidspesolo;
                quant = props.location.state.guidsquantity;
            }

            
            for (var [key, value] of guids) {
                let guidAtual = key;
                const quantAtual = quant.get(guidAtual);
                await api.get(serverApi.url + `${serverApi.versao}/ProdutoExtra/Resumo/${guidAtual}`)
                .then(function (response) {
                    let produto = response.data;
                    prodExtras.push({Guid: produto.Guid, Nome: produto.NomeProduto, Descricao: produto.DescricaoProduto, Preco: produto.ValorProduto, Quantidade: quantAtual});
                    //ABASTECER ARRAY DE GUIDS
                    handleChangeGuidsPE(produto.Guid);
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {   
                    setLoadingGrid(false);
                });
            }
            setPesSolo(prodExtras);
        }

        produtosExtras(prodExtras);
    }

    async function produtosExtras(prodExtras) {
        await api.get(serverApi.url + '/api/appUsuario/v1/ProdutoExtra/Todos')
        .then(function (response) {
            let listaExtrasBd = response.data;
            let listaExtrasIguaisTemp = [];
            for (let index = 0; index < listaExtrasBd.length; index++) {
                const element = listaExtrasBd[index];
                for (let i = 0; i < prodExtras.length; i++) {
                    const element2 = prodExtras[i];
                    if(element.Guid == element2.Guid) {
                        listaExtrasIguaisTemp.push(element);
                    }
                }
            }
            for (let index = 0; index < listaExtrasIguaisTemp.length; index++) {
                const element = listaExtrasIguaisTemp[index];
                listaExtrasBd.splice(listaExtrasBd.indexOf(element), 1);
            }
            setPE(listaExtrasBd);
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(() => {   
            //setLoadingGrid(false);
        });
    }

    function handleChangeGuidsPE(guid) {
        let listaGuids = guidsPE;
        if(listaGuids.indexOf(guid) < 0) {
            listaGuids.push(guid);
        } else {
            listaGuids.splice(listaGuids.indexOf(guid), 1);
        }
        setGuidsPE(listaGuids);
    }

    async function handlePayment () {
        setLoadingGrid(true);
        let email_user = localStorage.getItem("CLLogin");
        let id_plano = props.match.params.idplano;
        let id_plano_detalhe = props.match.params.idplanodetalhe;
        let guids_produtos_extras = guidsPE;
        
        var formdata = new FormData();
        formdata.append('email', email_user);
        //VERIFICA SE TEM PLANO ENVOLVIDO NA COMPRA
        if (id_plano != undefined && id_plano_detalhe != undefined) {
            formdata.append('plano', id_plano);
            formdata.append('plano_detalhe', id_plano_detalhe);
        }
        formdata.append('guids_produtos_extras', guids_produtos_extras);
        formdata.append('payment_method', "BOLETO");

        await api.post(serverApi.url + '/apicdl/ecommerce/gerapedido.php', formdata)
        .then(function (response) {
            console.log(response);
            
            let responseServer = response.data;
            if(responseServer.response == "success") {
                setPayment(true);
                setPaymentCode(responseServer.barCodeNumber);
                setPaymentLink(responseServer.linkBoleto);
                setNumPedido(responseServer.transactionId);

                //GOOGLE ANALYTICS
                let itemsGA = [];
                if (id_plano != undefined && id_plano_detalhe != undefined) {
                    items.map(function(plano) {
                        itemsGA.push({
                            "id": responseServer.transactionId + Math.floor(Math.random() * 99999),
                            "name": plano.Descricao + " Periodicidade: " + plano.Periodicidade,
                            "brand": "Condutor Legal",
                            "category": "Plano",
                            "list_position": 1,
                            "quantity": 1,
                            "price": parseFloat(plano.Preco)
                        });
                    });
                }
                window.gtag('event', 'purchase', {
                    "transaction_id": responseServer.transactionId,
                    "currency": "BRL",
                    "tax": 0.00,
                    "shipping": 0,
                    "items": itemsGA
                });
            }
        })
        .catch(function (error) {
            console.log(error);
        })
        .finally(() => {   
            setLoadingGrid(false);
        });
    }

    function onCopyToClipboard() {
        setSnackbar(true);
    }

    const handleClickClose = () => {
        setSnackbar(false);
      };

    return (
        <>
            <div style={{marginTop: '20px'}}>
                <MySnackbarSuccess showAlert={snackbar} mensagem="Copiado!" clickClose={handleClickClose} />
            </div>
            <LoadingGrid display={loadingGrid} />
            <div style={{display: (!loadingGrid ? '' : 'none')}}>
                <main className={classes.layout}>
                    <Paper className={classes.paper}>
                        <Typography component="h1" variant="h4" align="center">
                            Checkout
                        </Typography>
                        <Stepper activeStep={1} className={classes.stepper}>
                            <Step>
                                {payment ? ( 
                                    <StepLabel>{"Pagamento"}</StepLabel>
                                ) : (
                                    <StepLabel>{"Resumo do pedido"}</StepLabel>
                                )}
                            </Step>
                        </Stepper>
                        <React.Fragment>
                            {payment ? (
                                <React.Fragment>
                                    <div className={matches ? classes.boxPaymentMobile : classes.boxPayment}>
                                        <Typography variant="h4" classes={{ root: classes.titleBoleto }} align="center" gutterBottom>
                                            <b>Boleto gerado</b>
                                        </Typography>
                                        <p className={classes.descriptionBoleto}>Boleto bancário enviado no seu e-mail para pagamento, se prefirir pode acessá-lo: <a href={paymentLink} target="_blank">clicando aqui</a>.</p>
                                        <div className={classes.boxCode}>
                                            <div>
                                                <p className={classes.paymentCode}>{paymentCode}</p>
                                                <p>O número do seu pedido é {numPedido}</p>
                                                <CopyToClipboard text={paymentCode}
                                                    onCopy={onCopyToClipboard}>
                                                    <Button variant="contained" color="primary" className={classes.button}>
                                                        Copiar código para área de transferência
                                                    </Button>
                                                </CopyToClipboard>                                           
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            ) : (
                                
                                <React.Fragment>
                                    <ReviewBoleto items={items} pes={pe} pesSolo={pesSolo} handleChangeGuidsPE={handleChangeGuidsPE} />
                                    <div className={classes.buttons}>
                                        <Button variant="contained" onClick={handlePayment} color="primary" className={classes.button}>
                                            Pagar
                                        </Button>
                                    </div>
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    </Paper>
                </main>
            </div>
        </>
    );
}