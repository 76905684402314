import React from "react";
// @material-ui/core components
import ViewMulta from "components/View/ViewMulta.js";
import LoadingFull from "components/Loading/LoadingFull";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import {msg} from 'services/msg.js';
import MySnackbarErro from 'components/Uteis/MySnackbarErro.js';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';

  export default class MultaView extends React.Component {
  
    constructor(props) {
      super(props);
  
      checaLiberacaoMenu(props);

      this.state  = {
        multa: {Veiculo:{}},
        loadingGrid: true,
        isErro: false
      };
      
      
    } 
    componentDidMount(){
  
      let id = this.props.match.params.id;
      this.setState({isErro: false});

      api.get(serverApi.url + serverApi.versao + '/Multa/' + id + '/Resumo').then(response => {
        this.setState({multa: response.data});        
        //console.log(response.data);
      })
      .catch(error => {
        console.error(error);
        this.setState({isErro: true}); 
       
      })
      .finally(() => {   
        this.setState({
          loadingGrid: false
        });   
      });
      
    }
    
    render() {
    return (
      <>
          <LoadingFull display={this.state.loadingGrid} />
          
            <ViewMulta multa={this.state.multa} />
          {/* <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} /> */}
      </>
    );
  }
}
