import React from "react";
import GridContainer from "components/Grid/GridContainer.js";
import LoadingGrid from "components/Loading/LoadingGrid.js";


//const useStyles = makeStyles(styles);
import Painel from "components/Painel/Painel.js";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import {msg} from 'services/msg.js';
import SemRegistro from "components/Uteis/SemRegistro.js";
import MySnackbarErro from 'components/Uteis/MySnackbarErro.js';
import MeuPlanoAtual from 'components/Uteis/MeuPlanoAtual';
import ModalRecursoGratuito from 'components/Uteis/ModalRecursoGratuito';
import CustomizedTimeline from 'components/CustomizedTimeline/CustomizedTimeline';
import MenuDashboard from 'components/Painel/MenuDashboard';
import './style.css';

export default class Dashboard extends React.Component {
  
  constructor(props) {
    super(props);

    //TESTE PARA SABER DA PERMISSÃO DE ACESSO AOS MENUS
    let mensagemMenu = "";
    let permissaoMenu = false;
    if(props.location.state != undefined) {
      mensagemMenu = props.location.state.message_status_menu;
      permissaoMenu = true;
    }

    this.state  = {
      blocos: [],
      loadingGrid: true,
      isErro: false,
      isErroPermissaoMenu: permissaoMenu,
      mensagemMenuState: mensagemMenu
    };
  } 
  componentDidMount() {
    this.setState({isErro: false});
    this.setState({loadingGrid: true});

    let tela = (window.innerWidth > 959 ? (window.innerWidth > 1300 ? 'g' : 'm') : 'p')
    api.get(serverApi.url + serverApi.versao + '/TelaInicial/Load/' + tela).then(response => {
      //console.log("sssss",response);
      this.setState({
        blocos: response.data
      });
    })
    .catch(error => {
      console.error(error);
      this.setState({isErro: true});      
    })
    .finally(() => {   
      this.setState({loadingGrid: false});   
    });
    
  }
  
  render() {
  return (
    <>
      <MeuPlanoAtual />
      {/* <CustomizedTimeline /> */}
      <ModalRecursoGratuito />
      <MenuDashboard />
      <GridContainer>
        <LoadingGrid display={this.state.loadingGrid} />
        
        {this.state.isErroPermissaoMenu ? (
          <SemRegistro Exibe={true} Texto={this.state.mensagemMenuState} />
        ) : (
          ''
        )}
        {/*this.state.blocos.map((bloco) => {
          return (
            <Painel key={bloco.Id} bloco={bloco} />
          );
        })*/}
      </GridContainer>
      
          {/* <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} /> */}
     </>
    );
  }
}
