import React, { userState, useEffect, useState } from 'react';

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import LoadingGrid from "components/Loading/LoadingGrid";


const styles = {
    headerTitle: {
        textAlign: 'center',
        marginTop: 10
    },
    cardBorderTop: {
        borderTop: "2px #00ACC1 solid"
    },
    titleCard: {
        fontWeight: 900
    },
    columnNameInfo: {
        color: '#9ca1a3'
    },
    columnInfo: {
        color: '#000',
        fontWeight: 900
    }
};

const DefaultStyledTableCell = withStyles((theme) => ({
    body: {
      color: "#8c8888",
    },
  }))(TableCell);

const useStyles = makeStyles(styles);

export default function PlanoDetalhe(props) {
    const classes = useStyles();

    const [transaction, setTransaction] = useState([]);
    const [products, setProducts] = useState([]);
    const [qtdParcela, setQtdParcela] = useState(1);
    const [loadingGrid, setLoadingGrid] = useState(true); 

    useEffect(() => {          
        carregarDados();
    },[props] );

    async function carregarDados() {
        setLoadingGrid(true);
        
        let transaction_id = props.match.params.idvenda;
  
        var formdata = new FormData();
        formdata.append('function', 'get_details_transacation');
        formdata.append('transaction_id', transaction_id);
  
        await api.post(serverApi.url + '/apicdl/transacao.php', formdata)
        .then(function (response) {
            console.log("Response server ", response);
            let response_type = response.data.response;
            if (response_type == 'success') {
                let transaction = response.data.transaction;
                let products = response.data.produtos;
                setTransaction(transaction);
                setProducts(products);
                //Descobrir o QtdParcela
                let qtdParcela = 1;
                for (let index = 0; index < products.length; index++) {
                    const parcelas = products[index].QtdParcela;
                    if(parcelas > qtdParcela)
                        qtdParcela = parcelas;    
                }
                setQtdParcela(qtdParcela);
            }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {   
            setLoadingGrid(false);
        });
    }

    function formataData(data) {
        var dia = String(data).split("-")[2];
        var mes = String(data).split("-")[1];
        var ano = String(data).split("-")[0];

        return dia + '/' + mes + '/' + ano;
    }

    function formataStatusPedido(status_gateway) {
        let status = "";
        if (status_gateway == "authorized") {
            status = "Autorizada";
        } else if(status_gateway == "paid") {
            status = "Pago";
        } else if(status_gateway == "refunded") {
            status = "Estornado";
        } else if(status_gateway == "refused") {
            status = "Negado";
        }
        return status;
    }

    function formataValor(valor) {
        return parseFloat(valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

    function formataMetodoPagamento(forma_pgto) {
        let formaPgto = "";
        if (forma_pgto == "CARTAO") {
            formaPgto = "Cartão de crédito";
        }
        return formaPgto;
    }

    function formataImprecaoCartao(numero_cartao) {
        return parseInt(numero_cartao).toString().substr(-4);
    }

    function formataParcelas(qtd_parcelas, valor_plano, periodicidade) {
       /*  let valor_total = 0;
        if (periodicidade == 'A') {
            valor_total = valor_plano * 12;
        } else if(periodicidade == 'S') {
            valor_total = valor_plano * 6;
        } else if(periodicidade == 'M') {
            valor_total = valor_plano * 1;
        } */

        //let valor_parcelas = valor_total / qtd_parcelas;
        let valor_parcelas = valor_plano / qtd_parcelas;
        return qtd_parcelas + 'x ' + formataValor(valor_parcelas);
    }

    function formataValorUnitarioPlano(valor_plano, periodicidade) {
        let valor_total = 0;
        if (periodicidade == 'A') {
            valor_total = valor_plano * 12;
        } else if(periodicidade == 'S') {
            valor_total = valor_plano * 6;
        } else if(periodicidade == 'M') {
            valor_total = valor_plano * 1;
        }
        return formataValor(valor_total);
    }

    function imprimeVigencia(vigencia) {
        let vigencia_formatada = "";
        if (vigencia == 'A') {
            vigencia_formatada = "Anual";
        } else if(vigencia == 'S') {
            vigencia_formatada = "Semestral";
        } else if(vigencia == 'M') {
            vigencia_formatada = "Mensal";
        }
        return vigencia_formatada;
    }

    return (
        <> 
            <LoadingGrid display={loadingGrid} />
            <div style={{display: (!loadingGrid ? '' : 'none')}}>
                <h2 className={classes.headerTitle}>DETALHES DO PEDIDO {`#${props.match.params.idvenda}`}</h2>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        {transaction.GatewayPgto == 'PAGAR.ME' || 'PICPAY' ? (
                            <Card className={classes.cardBorderTop}>
                                <CardBody>
                                    <h4 className={classes.titleCard}>Informações do pedido</h4>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={5} lg={4}>
                                            <p className={classes.columnNameInfo}>Número do pedido:</p>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={7} lg={8}>
                                            <p className={classes.columnInfo}>{`#${transaction.TransactionId}`}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={5} lg={4}>
                                            <p className={classes.columnNameInfo}>Data do pedido:</p>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={7} lg={8}>
                                            <p className={classes.columnInfo}>{formataData(transaction.DataTransacao)}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={5} lg={4}>
                                            <p className={classes.columnNameInfo}>Status do pedido:</p>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={7} lg={8}>
                                            <p className={classes.columnInfo}>{formataStatusPedido(transaction.StatusGateway)}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={5} lg={4}>
                                            <p className={classes.columnNameInfo}>Valor total:</p>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={7} lg={8}>
                                            <p className={classes.columnInfo}>{formataValor(transaction.Amount)}</p>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        ) : (
                            ''
                        )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                        {transaction.GatewayPgto == 'PAGAR.ME' ? (
                            <Card className={classes.cardBorderTop}>
                                <CardBody>
                                    <h4 className={classes.titleCard}>Dados de pagamento</h4>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={5} lg={4}>
                                            <p className={classes.columnNameInfo}>Método de pagamento:</p>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={7} lg={8}>
                                            <p className={classes.columnInfo}>{formataMetodoPagamento(transaction.FormaPgto)}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={5} lg={4}>
                                            <p className={classes.columnNameInfo}>Nome:</p>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={7} lg={8}>
                                            <p className={classes.columnInfo}>{transaction.NameCardNumber}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={5} lg={4}>
                                            <p className={classes.columnNameInfo}>Cartão:</p>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={7} lg={8}>
                                            <p className={classes.columnInfo}>{`******${formataImprecaoCartao(transaction.NumberCard)}`}</p>
                                        </GridItem>
                                    </GridContainer>
                                    <GridContainer>
                                        <GridItem xs={4} sm={4} md={5} lg={4}>
                                            <p className={classes.columnNameInfo}>Parcelas:</p>
                                        </GridItem>
                                        <GridItem xs={8} sm={8} md={7} lg={8}>
                                            <p className={classes.columnInfo}>{formataParcelas(qtdParcela, transaction.Amount, "")}</p>
                                        </GridItem>
                                    </GridContainer>
                                </CardBody>
                            </Card>
                        ) : (
                            ''
                        )}
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <Card className={classes.cardBorderTop}>
                            <CardBody>
                                <h4 className={classes.titleCard}>Itens</h4>
                                <TableContainer component={Paper}>
                                    <Table size="large" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <DefaultStyledTableCell align="left">#</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">Tipo Produto</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">Descrição</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">Valor Unitário</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">Vigência Início</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">Vigência Fim</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">Vigência Contratada</DefaultStyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {products.map((product, key) => (
                                            <TableRow key={key}>
                                                <DefaultStyledTableCell align="left">{product.TipoProduto == 'PLANO' ? product.IdPlano : '-'}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">{product.TipoProduto}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">{product.TipoProduto == 'PLANO' ? product.Descricao : product.NomeProduto}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">{product.TipoProduto == 'PLANO' ? formataValorUnitarioPlano(product.ValorPlano, product.Periodicidade) : formataValor(product.ValorProduto)}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">{product.TipoProduto == 'PLANO' ? formataData(product.InicioVigencia) : formataData(product.DataTransacao)}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">{product.TipoProduto == 'PLANO' ? formataData(product.InicioVigencia) : '-'}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">{product.TipoProduto == 'PLANO' ? imprimeVigencia(product.Periodicidade) : '-'}</DefaultStyledTableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </>
    );
}