import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { Alert, AlertTitle } from '@material-ui/lab';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import iconeDetetiveDaMulta from "assets/img/icone-detetive-da-multa.png";
import iconeCNHLivre from "assets/img/icone-cnh-livre.png";
import iconeCNHBlindada from "assets/img/icone-cnh-blindada.png";
import iconeCondutorLegal from "assets/img/icone-condutor-legal.png";
import './plano.css'
import history from '../../services/history.js';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    headerTitle: {
        fontFamily: 'Montserrat, sans-serif',
        textAlign: 'center',
        marginTop: 10,
        fontSize: '2.4em',
        fontWeight: 400
    },
    boxAlert: {
        marginBottom: 10
    },
    btnPlanoPeriodicidade: {
        '& .MuiButton-label': {
            fontFamily: 'Montserrat, sans-serif',
        }
    },
    btnPlanoPeriodicidade: {
        borderTop: '5px solid #03A9F4!important',
        '& .MuiButton-label': {
            fontFamily: 'Montserrat, sans-serif',
        }
    },
    accordionPlanos: {
        borderTop: '5px solid #03A9F4',
        borderTopLeftRadius: '5px',
        borderTopRightRadius: '5px',
        marginTop: '10px'
    },
    accordionHeading: {
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 500,
        fontSize: '20px',
        textAlign: 'center'
    },
    accordionSummary: {
        '& .MuiAccordionSummary-content': {
            display: 'initial',
        }
    },
    boxImagemPlano: {
        textAlign: 'center'
    },
    imagemPlano: {
        width: '15%'
    },
    textDescricaoPlano: {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px',
        paddingLeft: '8px',
        paddingRight: '8px'
    },
    textDescricaoPlanoDestaque: {
        fontWeight: 500,
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '14px'
    },
    textItalico: {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontSize: '11px',
        paddingLeft: '8px',
        paddingRight: '8px',
        fontStyle: 'italic'
    },
    accordionDetails: {
        display: 'initial'
    },
    precoPlano: {
        color: '#03A9F4',
        textAlign: 'center',
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400
    },
    btnContratar: {
        fontFamily: 'Montserrat, sans-serif',
        backgroundColor: '#03A9F4',
        borderRadius: '20px',
        marginTop: '15px',
        width: '75%',
        color: '#FFF'
    },
    boxBtnContratar: {
        textAlign: 'center'
    }
};
  
const useStyles = makeStyles(styles);

export default function Planos(props) {
    const classes = useStyles();
    console.log(history);
    let showTitle = (typeof props.showTitle !== 'undefined') ? props.showTitle : true;

    const [ planosAnual, setPlanosAnual ] = useState(true);
    const [ planosSemestral, setPlanosSemestral ] = useState(false);
    const [ mostrarTitulo, setMostrarTitulo ] = useState(showTitle);
 
    function clickAnual() {
        setPlanosAnual(true);
        setPlanosSemestral(false);
    }

    function clickSemestral() {
        setPlanosAnual(false);
        setPlanosSemestral(true);
    }

    function submit(plano, plano_detalhe) {
        history.push(`/app/planos/contratar/forma-pagamento/${plano}/${plano_detalhe}`);
        window.location.reload();
    }

    return (
        <>
            <h2 style={{display: (showTitle) ? 'block' : 'none'}} className={classes.headerTitle}>Todos os Planos</h2>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div className={classes.boxAlert}>
                        <Alert severity="info" style={{fontFamily: 'Montserrat, sans-serif'}}>
                            <AlertTitle style={{fontFamily: 'Montserrat, sans-serif'}}>Informação</AlertTitle>
                            <strong>A cada 14,8 segundos uma pessoa recebe uma multa de trânsito no Brasil</strong>
                            <br />
                            Escolha o plano que melhor se encaixa no seu perfil e evite perder sua CNH. 
                        </Alert>
                    </div>
                </GridItem>
            </GridContainer>
            <div className={classes.root}>
                <ButtonGroup size="large"  aria-label="large outlined primary button group">
                    <Button className={(planosAnual) ? classes.btnPlanoPeriodicidade : classes.btnPeriodicidadeAtiva} onClick={clickAnual} color={ (planosAnual) ? 'primary' : '' }>Anual</Button>
                    <Button className={(planosSemestral) ? classes.btnPlanoPeriodicidade : classes.btnPeriodicidadeAtiva} onClick={clickSemestral} color={ (planosSemestral) ? 'primary' : '' }>Semestral</Button>
                </ButtonGroup>
            </div>
            <div style={{display: (planosAnual) ? '' : 'none'}}>
                <Accordion className={classes.accordionPlanos}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.boxImagemPlano}><img className={classes.imagemPlano} src={iconeDetetiveDaMulta} alt="Logo detetive da multa"/></p>
                        <Typography className={classes.accordionHeading}>Detetive da Multa</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <p className={classes.precoPlano}>R$ 9,90/mês</p>
                        <p className={classes.textDescricaoPlanoDestaque}>Chega de Perder Dinheiro com Multas!!</p>
                        <p className={classes.textDescricaoPlano}>Rastreie multas e recorra no prazo ou pague antecipadamente com desconto de 20%!!</p>
                        <p className={classes.textDescricaoPlanoDestaque}>O que está incluso:</p>
                        <p className={classes.textDescricaoPlano}>1 CNH / 1 VEÍCULO</p>
                        <p className={classes.textDescricaoPlano}>Descubra semanalmente se foi multado e recorra no prazo</p>
                        <p className={classes.textDescricaoPlano}>Não inclui recursos de multas pré e pós existentes</p>
                        <p className={classes.textDescricaoPlano}>R$ 50,00 por multa não mandatória (sem suspensão, sem cassação, sem lei seca)</p>
                        <p className={classes.boxBtnContratar}><Button className={classes.btnContratar} fullWidth color="info" onClick={() => submit(8,16)}>CONTRATAR AGORA</Button></p>
                    </AccordionDetails>
                </Accordion>

                <Accordion className={classes.accordionPlanos}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.boxImagemPlano}><img className={classes.imagemPlano} src={iconeCNHLivre} alt="Logo CNH Livre"/></p>
                        <Typography className={classes.accordionHeading}>CNH Livre</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <p className={classes.precoPlano}>R$ 49,90/mês</p>
                        <p className={classes.textDescricaoPlanoDestaque}>Chega de BUROCRACIA!</p>
                        <p className={classes.textDescricaoPlano}>Recorremos e protocolamos todas as suas multas dentro do prazo.</p>
                        <p className={classes.textDescricaoPlanoDestaque}>O que está incluso:</p>
                        <p className={classes.textDescricaoPlano}>1 CNH / 1 VEÍCULO</p>
                        <p className={classes.textDescricaoPlano}>Até 12 recursos de multa por ano com acompanhamento</p>
                        <p className={classes.textDescricaoPlano}>Até 6 Multas Pré-existentes</p>
                        <p className={classes.textDescricaoPlano}>Não inclui suspensão, cassação, LEI SECA e auto-suspensivas</p>
                        <p className={classes.textItalico}>*Para mais de uma CNH entrar em contato</p>
                        <p className={classes.boxBtnContratar}><Button className={classes.btnContratar} fullWidth color="info" onClick={() => submit(9,17)}>CONTRATAR AGORA</Button></p>
                    </AccordionDetails>
                </Accordion>

                <Accordion className={classes.accordionPlanos}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.boxImagemPlano}><img className={classes.imagemPlano} src={iconeCNHBlindada} alt="Logo CNH Blindada"/></p>
                        <Typography className={classes.accordionHeading}>CNH Blindada</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <p className={classes.precoPlano}>R$ 99,90/mês</p>
                        <p className={classes.textDescricaoPlanoDestaque}>Não perca sua CNH de uma hora para outra!</p>
                        <p className={classes.textDescricaoPlanoDestaque}>O que está incluso:</p>
                        <p className={classes.textDescricaoPlano}>1 CNH / 1 VEÍCULO</p>
                        <p className={classes.textDescricaoPlano}>Até 12 multas pré existentes (inclusive auto-suspensivas, exceto lei seca, cassação e suspensão)</p>
                        <p className={classes.textDescricaoPlano}>Até 30 Recursos de Multa por ano. ACOMPANHAMENTO TOTAL.</p>
                        <p className={classes.textDescricaoPlano}>Ideal para Motoristas Profissionais</p>
                        <p className={classes.textItalico}>*Para mais de uma CNH entrar em contato</p>
                        <p className={classes.boxBtnContratar}><Button className={classes.btnContratar} fullWidth color="info" onClick={() => submit(10,18)}>CONTRATAR AGORA</Button></p>
                    </AccordionDetails>
                </Accordion>

                <Accordion className={classes.accordionPlanos}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.boxImagemPlano}><img className={classes.imagemPlano} src={iconeCondutorLegal} alt="Logo Condutor Legal"/></p>
                        <Typography className={classes.accordionHeading}>Condutor Legal</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <p className={classes.precoPlano}>R$ 299,90/mês</p>
                        <p className={classes.textDescricaoPlanoDestaque}>Atendimento personalizado com Gabriel França</p>
                        <p className={classes.textDescricaoPlanoDestaque}>O que está incluso:</p>
                        <p className={classes.textDescricaoPlano}>1 CNH / 1 VEÍCULO</p>
                        <p className={classes.textDescricaoPlano}>Confecção de BRAT (doc de acidentes c/ vítima)</p>
                        <p className={classes.textDescricaoPlano}>Agendamento de Renovação de CNH</p>
                        <p className={classes.textDescricaoPlano}>Multas avulsas - nao auto-suspensivas R$ 50,00</p>
                        <p className={classes.textDescricaoPlano}>Auto suspensiva: R$ 300,00</p>
                        <p className={classes.textDescricaoPlano}>Multas Autosuspensivas, Lei Seca, Suspensão e Cassação</p>
                        <p className={classes.textItalico}>*Para mais de uma CNH entrar em contato</p>
                        <p className={classes.boxBtnContratar}><Button className={classes.btnContratar} fullWidth color="info" onClick={() => submit(11,19)}>CONTRATAR AGORA</Button></p>
                    </AccordionDetails>
                </Accordion>
            </div>
            <div style={{display: (planosSemestral) ? '' : 'none'}}>
                <Accordion className={classes.accordionPlanos}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.boxImagemPlano}><img className={classes.imagemPlano} src={iconeDetetiveDaMulta} alt="Logo detetive da multa"/></p>
                        <Typography className={classes.accordionHeading}>Detetive da Multa</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <p className={classes.precoPlano}>R$ 14,90/mês</p>
                        <p className={classes.textDescricaoPlanoDestaque}>Chega de Perder Dinheiro com Multas!!</p>
                        <p className={classes.textDescricaoPlano}>Rastreie multas e recorra no prazo ou pague antecipadamente com desconto de 20%!!</p>
                        <p className={classes.textDescricaoPlanoDestaque}>O que está incluso:</p>
                        <p className={classes.textDescricaoPlano}>1 CNH / 1 VEÍCULO</p>
                        <p className={classes.textDescricaoPlano}>Descubra semanalmente se foi multado e recorra no prazo</p>
                        <p className={classes.textDescricaoPlano}>Não inclui recursos de multas pré e pós existentes</p>
                        <p className={classes.textDescricaoPlano}>R$ 50,00 por multa não mandatória (sem suspensão, sem cassação, sem lei seca)</p>
                        <p className={classes.boxBtnContratar}><Button className={classes.btnContratar} fullWidth color="info" onClick={() => submit(8,20)}>CONTRATAR AGORA</Button></p>
                    </AccordionDetails>
                </Accordion>

                <Accordion className={classes.accordionPlanos}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.boxImagemPlano}><img className={classes.imagemPlano} src={iconeCNHLivre} alt="Logo CNH Livre"/></p>
                        <Typography className={classes.accordionHeading}>CNH Livre</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <p className={classes.precoPlano}>R$ 59,90/mês</p>
                        <p className={classes.textDescricaoPlanoDestaque}>Chega de BUROCRACIA!</p>
                        <p className={classes.textDescricaoPlano}>Recorremos e protocolamos todas as suas multas dentro do prazo.</p>
                        <p className={classes.textDescricaoPlanoDestaque}>O que está incluso:</p>
                        <p className={classes.textDescricaoPlano}>1 CNH / 1 VEÍCULO</p>
                        <p className={classes.textDescricaoPlano}>Até 12 recursos de multa por ano com acompanhamento</p>
                        <p className={classes.textDescricaoPlano}>Até 6 Multas Pré-existentes</p>
                        <p className={classes.textDescricaoPlano}>Não inclui suspensão, cassação, LEI SECA e auto-suspensivas</p>
                        <p className={classes.textItalico}>*Para mais de uma CNH entrar em contato</p>
                        <p className={classes.boxBtnContratar}><Button className={classes.btnContratar} fullWidth color="info" onClick={() => submit(9,21)}>CONTRATAR AGORA</Button></p>
                    </AccordionDetails>
                </Accordion>

                <Accordion className={classes.accordionPlanos}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.boxImagemPlano}><img className={classes.imagemPlano} src={iconeCNHBlindada} alt="Logo CNH Blindada"/></p>
                        <Typography className={classes.accordionHeading}>CNH Blindada</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <p className={classes.precoPlano}>R$ 119,90/mês</p>
                        <p className={classes.textDescricaoPlanoDestaque}>Não perca sua CNH de uma hora para outra!</p>
                        <p className={classes.textDescricaoPlanoDestaque}>O que está incluso:</p>
                        <p className={classes.textDescricaoPlano}>1 CNH / 1 VEÍCULO</p>
                        <p className={classes.textDescricaoPlano}>Até 12 multas pré existentes (inclusive auto-suspensivas, exceto lei seca, cassação e suspensão)</p>
                        <p className={classes.textDescricaoPlano}>Até 30 Recursos de Multa por ano. ACOMPANHAMENTO TOTAL.</p>
                        <p className={classes.textDescricaoPlano}>Ideal para Motoristas Profissionais</p>
                        <p className={classes.textItalico}>*Para mais de uma CNH entrar em contato</p>
                        <p className={classes.boxBtnContratar}><Button className={classes.btnContratar} fullWidth color="info" onClick={() => submit(10,22)}>CONTRATAR AGORA</Button></p>
                    </AccordionDetails>
                </Accordion>

                <Accordion className={classes.accordionPlanos}>
                    <AccordionSummary
                        className={classes.accordionSummary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.boxImagemPlano}><img className={classes.imagemPlano} src={iconeCondutorLegal} alt="Logo Condutor Legal"/></p>
                        <Typography className={classes.accordionHeading}>Condutor Legal</Typography>
                    </AccordionSummary>
                    <AccordionDetails className={classes.accordionDetails}>
                        <p className={classes.precoPlano}>R$ 359,90/mês</p>
                        <p className={classes.textDescricaoPlanoDestaque}>Atendimento personalizado com Gabriel França</p>
                        <p className={classes.textDescricaoPlanoDestaque}>O que está incluso:</p>
                        <p className={classes.textDescricaoPlano}>1 CNH / 1 VEÍCULO</p>
                        <p className={classes.textDescricaoPlano}>Confecção de BRAT (doc de acidentes c/ vítima)</p>
                        <p className={classes.textDescricaoPlano}>Agendamento de Renovação de CNH</p>
                        <p className={classes.textDescricaoPlano}>Multas avulsas - nao auto-suspensivas R$ 50,00</p>
                        <p className={classes.textDescricaoPlano}>Auto suspensiva: R$ 300,00</p>
                        <p className={classes.textDescricaoPlano}>Multas Autosuspensivas, Lei Seca, Suspensão e Cassação</p>
                        <p className={classes.textItalico}>*Para mais de uma CNH entrar em contato</p>
                        <p className={classes.boxBtnContratar}><Button className={classes.btnContratar} fullWidth color="info" onClick={() => submit(11,23)}>CONTRATAR AGORA</Button></p>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    );
}