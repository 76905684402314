import React, { useState, useEffect } from "react";

import { makeStyles, withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';

import Fab from '@material-ui/core/Fab';
import Alert from '@material-ui/lab/Alert';
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import ButtonMI from '@material-ui/core/Button';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import LoadingGrid from "components/Loading/LoadingGrid";
import Planos from './Planos';

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';


const styles = {
    table: {
        minWidth: '100%',
    },
    headerTitle: {
        textAlign: 'center',
        marginTop: 10,
        fontFamily: 'Montserrat, sans-serif',
        fontWeight: 400
    },
    textHeadTable: {
        fontSize: 15,
        fontWeight: 900,
        color: '#000'
    },
    fabButton: {
        fontSize: '17px',
        marginBottom: 25,
        backgroundColor: '#49b24b',
        color: '#FFFFFF',
        width: '100%',
        fontFamily: 'Montserrat, sans-serif',
        borderRadius: '50px',
        '&:hover': {
            backgroundColor: '#53c857',
            color: '#FFFFFF',
        }
    }
};

const DefaultStyledTableCell = withStyles((theme) => ({
    body: {
      color: "#8c8888",
    },
  }))(TableCell);
  
const useStyles = makeStyles(styles);


export default function Plano(props) {
    const classes = useStyles();

    const [planos, setPlanos] = useState([]);
    const [loadingGrid, setLoadingGrid] = useState(true);
    
    //IMPLEMENTAR LOADER

    useEffect(() => {          
        carregarDados();
    },[props] );

    function carregarDados() {
        setLoadingGrid(true);
        
        let email_user = localStorage.getItem("CLLogin");
  
        var formdata = new FormData();
        formdata.append('function', 'get_planos_by_email');
        formdata.append('email_usuario', email_user);
  
        api.post(serverApi.url + '/apicdl/plano_vigencia.php', formdata)
        .then(function (response) {
            //console.log(response);
            let response_type = response.data.response;
            if (response_type == 'success') {
                let planos = response.data.planos;
                setPlanos(planos);
            }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {   
            setLoadingGrid(false);
        });
    }

    function formataData(data) {
        /* let dataFormat = new Date(data);
        let dataFormatada = dataFormat.toLocaleDateString();
        return dataFormatada; */
        let ano = data.split("-")[0];
        let mes = data.split("-")[1];
        let dia = data.split("-")[2];
        return `${dia}/${mes}/${ano}`;
    }

    function calculaValorPlano(valorPlano, periodicidade) {
        let valor_total = 0;
        if (periodicidade == 'A') {
            valor_total = valorPlano * 12;
        } else if(periodicidade == 'S') {
            valor_total = valorPlano * 6;
        } else if(periodicidade == 'M') {
            valor_total = valorPlano * 1;
        }
        return valor_total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    
    return (
        <>
            <LoadingGrid display={loadingGrid} />
            <div style={{display: (!loadingGrid ? '' : 'none')}}>
                <h2 className={classes.headerTitle}>Planos</h2>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        {planos.length == 0 ? (
                            <>
                                <Alert severity="info" style={{fontFamily: 'Montserrat, sans-serif'}}>
                                    Nenhum plano ativo no momento. Contrate um Agora!
                                </Alert>
                                <Planos showTitle={false} props={props} />
                            </>
                        ) : (
                            <div>
                                <Fab variant="extended" classes={{root: classes.fabButton}} onClick={() => { props.history.push(`/app/planos/contratar`); }}>
                                    CONTRATAR NOVO PLANO
                                </Fab>
                                <TableContainer component={Paper}>
                                    <Table size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <DefaultStyledTableCell align="left">Plano</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">Data pedido</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">Valor</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="right">Ação</DefaultStyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {planos.map((plano, key) => (
                                            <TableRow key={key}>
                                                <DefaultStyledTableCell align="left">{plano.Descricao}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">{formataData(plano.DataCadastro)}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="left">{calculaValorPlano(plano.ValorPlano, plano.Periodicidade)}</DefaultStyledTableCell>
                                                <DefaultStyledTableCell align="right">
                                                    <ButtonMI 
                                                        variant="contained" 
                                                        size="small"
                                                        disabled={(parseInt(plano.Ativo) == 1) ? false : true}
                                                        onClick={() => {
                                                            props.history.push(`/app/planos/detalhes/${plano.TransactionId}`);
                                                        }}>
                                                        <VisibilityOutlinedIcon />
                                                    </ButtonMI>
                                                </DefaultStyledTableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        )}
                    </GridItem>
                </GridContainer>
            </div>
        </>
    );
    

}
