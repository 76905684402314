import React, { useState } from "react";
// core components
import PropTypes from "prop-types";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import AddIcon from "@material-ui/icons/Add";
import TextField from "@material-ui/core/TextField";
// style="justify-content: space-evenly;"

function FileButton(props) {
  const [docName, setDocName] = useState("");

  return (
    <>
      <GridContainer>
        <GridItem md={2}></GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <br />
          <GridContainer style={{ justifyContent: "space-evenly" }}>
            <GridItem xs={10} sm={11} md={8}>
              <TextField
                margin="dense"
                disabled
                fullWidth
                label={props.label}
                value={docName}
                helperText={props.helper}
              />
            </GridItem>
            <GridItem md={4}>
              <Button variant="contained" component="label" color="info">
                {" "}
                <AddIcon /> Upload de arquivo
                <input
                  type="file"
                  accept="image/* , application/pdf"
                  name="Arquivos[]"
                  hidden
                  dataName={props.dataName}
                  onChange={e => {
                    if (e.target.files[0]) {
                      setDocName(e.target.files[0].name);
                    } else setDocName("");
                  }}
                />
              </Button>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </>
  );
}
export default FileButton;

FileButton.propTypes = {
  label: PropTypes.string,
  helper: PropTypes.string,
  dataName: PropTypes.string
};
