import React from "react";
// core components
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

function ViewRecurso(props) {
  //const formatDate = (dateString) => new Date(dateString).toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 1);
    return date.toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };
  //crie uma função para formatar a data subbitraindo 20 dias da data passada no parâmetro
  const formatDate20 = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 19);
    return date.toLocaleString('pt-br', { year: 'numeric', month: '2-digit', day: '2-digit' });
  };



  return (
    <>
      <div style={{ paddingTop: "35px" }}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <TableCell>Auto de Infração</TableCell>
                <TableCell align="center">Data Última Consulta</TableCell>
                <TableCell align="center">Estágio</TableCell>
                <TableCell align="center">Situação</TableCell>
                <TableCell align="center">Protocolo</TableCell>
                <TableCell align="center">Placa</TableCell>
                <TableCell align="center">Renavam</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.recursos.map((rec, i) => {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {rec.AutoDeInfracao}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Protocolado == "S" && !rec.DataConsultaOrgao ? formatDate(rec.DataProtocolado) : rec.DataConsultaOrgao ? rec.Estagio == "Em Recurso" ? formatDate20(rec.DataConsultaOrgao) : formatDate(rec.DataConsultaOrgao) : "-"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.TipoDefesa}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Impresso != "S" && rec.Protocolado != "S" && (rec.TipoDefesa == "Defesa Prévia" || rec.TipoDefesa == "1ª Instância" || rec.TipoDefesa == "2ª Instância") ? "Em Processamento" : rec.Estagio}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Protocolo ?? "-"}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Placa}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {rec.Renavam}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
}
export default ViewRecurso;
