import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import PaymentForm from './PaymentForm';
import Review from './Review';
import LoadingGrid from "components/Loading/LoadingGrid.js";
import LoadingPayment from "components/Loading/LoadingPayment";

import api from 'services/api.js';
import {serverApi} from 'services/constante.js';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = [ 'Detalhes de pagamento', 'Resumo do pedido'];

export default function Checkout(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [mensagemPedido, setMensagemPedido] = useState("");
  const [typeMessage, setTypeMessage] = useState("");
  //ESTADOS FORMULÁRIO
  const [card_name, setCardName] = useState("");
  const [error_card_name, setErrorCardName] = useState(false);
  const [card_cpf, setCardCpf] = useState("");
  const [error_card_cpf, setErrorCardCpf] = useState(false);
  const [card_mes, setCardMes] = useState("");
  const [error_card_mes, setErrorCardMes] = useState(false);
  const [card_ano, setCardAno] = useState("");
  const [error_card_ano, setErrorCardAno] = useState(false);
  const [card_number, setCardNumber] = useState("");
  const [error_card_number, setErrorCardNumber] = useState(false);
  const [card_cvv, setCardCVV] = useState("");
  const [error_card_cvv, setErrorCardCvv] = useState(false);
  const [card_brand, setCardBrand] = useState("");
  const [error_card_brand, setErrorCardBrand] = useState(false);
  const [parcelas, setParcelas] = useState(1);
  //ITEMS
  const [items, setItems] = useState([]);
  const [pe, setPE] = useState([]);// Produtos Extras
  const [guidsPE, setGuidsPE] = useState([]);// Guids Produtos Extras
  const [pesSolo, setPesSolo] = useState([]);// Produtos Extras SEPARADOS

  function handleCardName(value) { setCardName(value); setErrorCardName(false); }
  function handleCardCpf(value) { setCardCpf(value); setErrorCardCpf(false); }
  function handleCardMes(value) { setCardMes(value); setErrorCardMes(false); }
  function handleCardAno(value) { setCardAno(value); setErrorCardAno(false); }
  function handleCardNumber(value) { setCardNumber(value); setErrorCardNumber(false); }
  function handleCardCVV(value) { setCardCVV(value); setErrorCardCvv(false); }
  function handleCardBrand(value) { setCardBrand(value); setErrorCardBrand(false); }
  function handleParcelas(value) { setParcelas(value); }
  
  useEffect(() => {          
    carregarDados();
  },[props] );

  async function carregarDados() {
      setLoadingGrid(true);
      
      let prodExtras = [];
      let id_plano = props.match.params.idplano;
      let id_plano_detalhe = props.match.params.idplanodetalhe;

      if (id_plano != undefined && id_plano_detalhe != undefined) {
        var formdata = new FormData();
        formdata.append('function', 'get_plano_by_id_plano_id_plano_detalhe');
        formdata.append('id_plano', id_plano);
        formdata.append('id_plano_detalhe', id_plano_detalhe);

        await api.post(serverApi.url + '/apicdl/plano.php', formdata)
        .then(function (response) {
          //console.log(response);
          let plano = response.data.plano;
          setItems([{Descricao: plano.Descricao, Periodicidade: plano.Periodicidade, Preco: plano.Preco}]);

          //GOOGLE ANALYTICS
          window.gtag('event', 'select_content', {
            "content_type": "product",
            "items": [
            {
                "id": id_plano + "_" + id_plano_detalhe,
                "name": plano.Descricao + " Periodicidade: " + plano.Periodicidade,
                "brand": "Condutor Legal",
                "category": "Plano",
                "quantity": 1,
                "price": parseFloat(plano.Preco)
            }
          ]});
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {   
          setLoadingGrid(false);
          //produtosExtras();
        });
      } else {

        let guids = "";
        let quant = "";
        if(props.location.state != undefined) {
          guids = props.location.state.guidspesolo;
          quant = props.location.state.guidsquantity;
        }

        //let prodExtras = [];
        for (var [key, value] of guids) {
          let guidAtual = key;
          const quantAtual = quant.get(guidAtual);
          await api.get(serverApi.url + `${serverApi.versao}/ProdutoExtra/Resumo/${guidAtual}`)
          .then(function (response) {
            let produto = response.data;
            prodExtras.push({Guid: produto.Guid, Nome: produto.NomeProduto, Descricao: produto.DescricaoProduto, Preco: produto.ValorProduto, Quantidade: quantAtual});
            //ABASTECER ARRAY DE GUIDS
            handleChangeGuidsPE(produto.Guid);
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => {   
            setLoadingGrid(false);
          });
        }
        setPesSolo(prodExtras);
      }

      produtosExtras(prodExtras);
  }

  async function produtosExtras(prodExtras) {
    await api.get(serverApi.url + '/api/appUsuario/v1/ProdutoExtra/Todos')
    .then(function (response) {
        let listaExtrasBd = response.data;
        let listaExtrasIguaisTemp = [];
        for (let index = 0; index < listaExtrasBd.length; index++) {
            const element = listaExtrasBd[index];
            for (let i = 0; i < prodExtras.length; i++) {
                const element2 = prodExtras[i];
                if(element.Guid == element2.Guid) {
                    listaExtrasIguaisTemp.push(element);
                } 
            }
        }
        for (let index = 0; index < listaExtrasIguaisTemp.length; index++) {
            const element = listaExtrasIguaisTemp[index];
            listaExtrasBd.splice(listaExtrasBd.indexOf(element), 1);
        }
        setPE(listaExtrasBd);
    })
    .catch(function (error) {
        console.log(error);
    })
    .finally(() => {   
        //setLoadingGrid(false);
    });
}

  function getStepContent(step) {
    switch (step) {
      //case 0:
        //return <AddressForm />;
      case 0:
        return <PaymentForm
                  handleCardName={handleCardName}
                  stateCardName={card_name}
                  stateErrorCardName={error_card_name} 
                  handleCardCpf={handleCardCpf}
                  stateCpf={card_cpf}
                  stateErrorCardCpf={error_card_cpf}
                  handleCardMes={handleCardMes}
                  stateMes={card_mes}
                  stateErrorCardMes={error_card_mes}
                  handleCardAno={handleCardAno}
                  stateAno={card_ano}
                  stateErrorCardAno={error_card_ano}
                  handleCardNumber={handleCardNumber}
                  stateCardNumber={card_number}
                  stateErrorCardNumber={error_card_number}
                  handleCardCVV={handleCardCVV}
                  stateCardCVV={card_cvv}
                  stateErrorCardCvv={error_card_cvv}
                  handleCardBrand={handleCardBrand}
                  stateBrand={card_brand}
                  stateErrorCardBrand={error_card_brand}
                  handleParcelas={handleParcelas}
                  stateParcelas={parcelas}
                  items={items}
                  pesSolo={pesSolo}
                />;
      case 1:
        return <Review 
                  stateCardBrand={card_brand}
                  stateCardName={card_name}
                  stateCardNumber={card_number}
                  stateCardMes={card_mes}
                  stateCardAno={card_ano}
                  stateCardCpf={card_cpf}
                  items={items}
                  pes={pe} 
                  pesSolo={pesSolo}
                  handleChangeGuidsPE={handleChangeGuidsPE}
                />;
      default:
        throw new Error('Unknown step');
    }
  }

  const handleNext = () => {
    if(activeStep == 0) {
      let saida = false;
      if(card_name == "") { setErrorCardName(true); saida = true; }
      if(card_cpf == "") { setErrorCardCpf(true); saida = true; }
      if(card_number == "") { setErrorCardNumber(true); saida = true; }
      if(card_mes == "") { setErrorCardMes(true); saida = true; }
      if(card_ano == "") { setErrorCardAno(true); saida = true; }
      if(card_cvv == "") { setErrorCardCvv(true); saida = true; }
      if(card_brand == "") { setErrorCardBrand(true); saida = true; }
      
      if(saida) {
        return false;
      }
    }

    if(activeStep == 1) {
      handleSubmitPayment();
    }
    setActiveStep(activeStep + 1);
  };

  async function handleSubmitPayment() {
    setLoadingPayment(true);
    let email_user = localStorage.getItem("CLLogin");
    let id_plano = props.match.params.idplano;
    let id_plano_detalhe = props.match.params.idplanodetalhe;
    let guids_produtos_extras = guidsPE;

    var formdata = new FormData();
    formdata.append('email', email_user);
    formdata.append('card_name', card_name);
    formdata.append('card_mes', card_mes);
    formdata.append('card_ano', card_ano);
    formdata.append('card_number', card_number);
    formdata.append('card_cvv', card_cvv);
    formdata.append('parcelas', parcelas);
    //VERIFICA SE TEM PLANO ENVOLVIDO NA COMPRA
    if (id_plano != undefined && id_plano_detalhe != undefined) {
      formdata.append('plano', id_plano);
      formdata.append('plano_detalhe', id_plano_detalhe);
    }
    formdata.append('guids_produtos_extras', guids_produtos_extras);
    formdata.append('payment_method', "CARTAO");
    //formdata.append('assinatura', "ASSINATURA");
    //formdata.append('idPlanoBp', 1);
    
    //await api.post(serverApi.url + '/apicdl/ecommerce/gerapedido.php', formdata)
    await api.post(serverApi.url + '/apicdl/ecommerce/gerapedido2.php', formdata)
    .then(function (response) {
      //console.log("resposta", response);
      if(response.data.response == "success") {
        let transaction = response.data.transaction;
        let status_transaction = transaction.status;
        if (status_transaction == "authorized" || status_transaction == "trialing") {
          setMensagemPedido('Pedido (#' + transaction.id + ') autorizado, obrigado por sua compra. Em breve você receberá um e-mail com as informações do mesmo. Caso tenha alguma dúvida, entrar em contato conosco através do e-mail contato@condutorlegal.com.');
          setTypeMessage("info");

          //GOOGLE ANALYTICS
          let itemsGA = [];
          if (id_plano != undefined && id_plano_detalhe != undefined) {
            items.map(function(plano) {
                itemsGA.push({
                    "id": transaction.id + Math.floor(Math.random() * 99999),
                    "name": plano.Descricao + " Periodicidade: " + plano.Periodicidade,
                    "brand": "Condutor Legal",
                    "category": "Plano",
                    "list_position": 1,
                    "quantity": 1,
                    "price": parseFloat(plano.Preco)
                });
            });
          }
          window.gtag('event', 'purchase', {
              "transaction_id": transaction.id,
              "currency": "BRL",
              "tax": 0.00,
              "shipping": 0,
              "items": itemsGA
          });

        } else if (status_transaction == "paid") {
          setMensagemPedido('Parabéns, obrigado por sua compra. Abaixo segue informações do seu pedido (#' + transaction.id + '), em breve você receberá um e-mail com as informações do mesmo. Se quiser acompanhar o pedido pelo app acesse o menu "Planos" e visualizar mais detalhes. Caso tenha alguma dúvida, entrar em contato conosco através do e-mail contato@condutorlegal.com.');
          setTypeMessage("success");
        } 
      } else if (response.data.response == "error") {
        
        if (response.data.transaction.status == "refused") {
          setMensagemPedido('Pedido Negado. Infelizmente não conseguimos finalizar sua compra, verifique os dados do seu cartão e tente novamente. Caso o erro persistir entre em contato conosco através do e-mail contato@condutorlegal.com.');
          setTypeMessage("error");
        }

        let object = {};
        formdata.forEach(function(value, key){
            object[key] = value;
        });
        let dataFormat = new Date();
        let error_log = dataFormat + " / Response [" + JSON.stringify(response.data) + "] / Dados Enviados [" + JSON.stringify(object) + "]";
        var formdataLog = new FormData();
        formdataLog.append('data', error_log);
        api.post(serverApi.url + '/apicdl/ecommerce/escreve_log.php', formdataLog)
        .then(function (response) { });
        
        let mensagem_aux = "";
        if(response.data.parameters.parameter_name != undefined && response.data.parameters.parameter_name != null) mensagem_aux = `[${response.data.parameters}]`;
        if(response.data.parameters.message != undefined && response.data.parameters.message != "") mensagem_aux = ` Mensagem erro: ${response.data.parameters.message} `;

        setMensagemPedido("Infelizmente ocorreu um erro na geração de sua compra, tente novamente efetuar a compra revendo os dados de pagamento se estão corretos. Caso o erro persistir entre em contato no email contato@condutorlegal.com." + mensagem_aux);
        setTypeMessage("error");
      }
    })
    .catch(function (error) {
      setMensagemPedido("Infelizmente ocorreu um erro na geração de sua compra, tente novamente efetuar a compra revendo os dados de pagamento se estão corretos. Caso o erro persistir entre em contato no email contato@condutorlegal.com.");
      setTypeMessage("error");
      console.log(error);
    })
    .finally(() => {   
      setLoadingPayment(false);
    });

  }

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  function handleChangeGuidsPE(guid) {
    let listaGuids = guidsPE;
    if(listaGuids.indexOf(guid) < 0) {
        listaGuids.push(guid);
    } else {
        listaGuids.splice(listaGuids.indexOf(guid), 1);
    }
    setGuidsPE(listaGuids);
}

  return (
    <> 
      <LoadingGrid display={loadingGrid} />
      <LoadingPayment display={loadingPayment} />
      <div style={{display: (!loadingGrid ? '' : 'none')}}>
        <React.Fragment>
          <CssBaseline />
          <main className={classes.layout} id="boxPagamento">
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h4" align="center">
                Checkout
              </Typography>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <React.Fragment>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography variant="h5" gutterBottom>
                      Obrigado por seu pedido.
                    </Typography>
                    <Alert variant="filled" severity={typeMessage}>
                      {mensagemPedido}
                    </Alert>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getStepContent(activeStep)}
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} className={classes.button}>
                          Voltar
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? 'Pagar' : 'Próximo'}
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            </Paper>
          </main>
        </React.Fragment>
      </div>
    </>
  );
}