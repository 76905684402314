import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { makeStyles } from "@material-ui/core/styles";
import ViewPoliticaDePrivacidadeESeguranca from 'components/View/ViewPoliticaDePrivacidadeESeguranca';
import ViewPoliticaDePagamentos from 'components/View/ViewPoliticaDePagamentos';
import ViewTermosECondicoes from 'components/View/ViewTermosECondicoes';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const styles = {
    appBar: {
        position: 'relative',
        backgroundColor: '#00ACC1',
    }
};
const useStyles = makeStyles(styles);

export default function Termos(props) {
    const [openPrivacidadeESeguranca, setOpenPrivacidadeESeguranca] = useState(false);
    const [openPoliticaDePagamentos, setOpenPoliticaDePagamentos] = useState(false);
    const [openTermosECondicoes, setOpenTermosECondicoes] = useState(false);
    const classes = useStyles();

    const handleOpenPrivacidadeESeguranca = () => {
        setOpenPrivacidadeESeguranca(true);
    };

    const handleClosePrivacidadeESeguranca = () => {
        setOpenPrivacidadeESeguranca(false);
    };

    const handleOpenPoliticaDePagamentos = () => {
        setOpenPoliticaDePagamentos(true);
    };

    const handleClosePoliticaDePagamentos = () => {
        setOpenPoliticaDePagamentos(false);
    };

    const handleOpenTermosECondicoes = () => {
        setOpenTermosECondicoes(true);
    };

    const handleCloseTermosECondicoes = () => {
        setOpenTermosECondicoes(false);
    };

    return (
        <>
            <Dialog 
                fullScreen 
                open={openPrivacidadeESeguranca} 
                TransitionComponent={Transition} 
                maxWidth={'sm'}
                aria-labelledby="max-width-dialog-title"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={handleClosePrivacidadeESeguranca} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <p style={{marginTop: '20px'}}>Política de Privacidade e Segurança</p>
                    </Toolbar>
                </AppBar>
                <ViewPoliticaDePrivacidadeESeguranca />
            </Dialog>
            <Dialog 
                fullScreen 
                open={openPoliticaDePagamentos} 
                TransitionComponent={Transition} 
                maxWidth={'sm'}
                aria-labelledby="max-width-dialog-title"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={handleClosePoliticaDePagamentos} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <p style={{marginTop: '20px'}}>Política de Pagamentos</p>
                    </Toolbar>
                </AppBar>
                <ViewPoliticaDePagamentos />
            </Dialog>
            <Dialog 
                fullScreen 
                open={openTermosECondicoes} 
                TransitionComponent={Transition} 
                maxWidth={'sm'}
                aria-labelledby="max-width-dialog-title"
            >
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton color="inherit" onClick={handleCloseTermosECondicoes} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <p style={{marginTop: '20px'}}>Termos e Condições</p>
                    </Toolbar>
                </AppBar>
                <ViewTermosECondicoes />
            </Dialog>
            <h2 style={{marginTop: '20px', textAlign: 'center'}}>Termos</h2>
            <ul>
                {/*<li><a href="#" onClick={handleOpenPrivacidadeESeguranca}>Política de Privacidade e Segurança</a></li>
                <li><a href="#" onClick={handleOpenPoliticaDePagamentos}>Política de Pagamentos</a></li>
                <li><a href="#" onClick={handleOpenTermosECondicoes}>Termos e Condições</a></li>*/}
                <li><a href="https://condutorlegal.com.br/TERMOS-DE-USO-E-CONDICOES-E-POLITICAS-Versao-2-Atualizacao-20-08-22.pdf" target="_blank">Termos de Uso/Condições e Políticas de Privacidade e Segurança. Pagamentos e Cancelamentos</a></li>
            </ul>
        </>
    );
}