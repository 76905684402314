import React from "react";
import GridNotificacao from "components/Grid/GridNotificacao.js";
import LoadingGrid from "components/Loading/LoadingGrid.js";
import api from 'services/api.js';
import {serverApi} from 'services/constante.js';
import {msg} from 'services/msg.js';
import SemRegistro from "components/Uteis/SemRegistro.js";
import MySnackbarErro from 'components/Uteis/MySnackbarErro.js';
import checaLiberacaoMenu from 'services/checaLiberacaoMenu';
import MeuPlanoAtual from 'components/Uteis/MeuPlanoAtual';
 
export default class Notificacoes extends React.Component {
  
  constructor(props) {
    super(props);

    checaLiberacaoMenu(props);

    this.state  = {
      notificacoes: [],
      loadingGrid: true,
      isErro: false
    };
  } 
  componentDidMount(){

    this.setState({isErro: false});
    this.setState({loadingGrid: true});
    api.get(serverApi.url + serverApi.versao + '/Notificacao/Notificacoes').then(response => {
      this.setState({
        notificacoes: response.data
      });
      
      console.log(response.data);
    })
    .catch(error => {
      console.error(error);
      this.setState({isErro: true});      
     
    })
    .finally(() => {   
      this.setState({
        loadingGrid: false
      });   
    });
    
  }
  
  render() {
  return (
      //<GridContainer>
      <>
        <LoadingGrid display={this.state.loadingGrid} />
        <MeuPlanoAtual />
        <p style={{marginTop: 10}}>Olá Condutor (a)! Mantenha sempre seu e-mail atualizado para receber as notificações.</p>
        <SemRegistro Exibe={!this.state.isErro && this.state.notificacoes.length <= 0 && !this.state.loadingGrid} Texto={'Ainda não existe notificação para ser exibida! '} />
          <GridNotificacao notificacoes={this.state.notificacoes} />
          {/* <MySnackbarErro IsErro={this.state.isErro} Mensagem={msg.semConexao} /> */}
     </>
      //</GridContainer>
    );
  }
}