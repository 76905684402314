import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { serverApi } from 'services/constante.js';

// @material-ui/core components
//import { makeStyles } from "@material-ui/core/styles";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
//import CustomInput from "components/CustomInput/CustomInput.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import bgImage1 from "assets/img/background-login-1.jpeg";
import bgImage2 from "assets/img/background-login-1.jpeg";
import bgImage3 from "assets/img/background-login-1.jpeg";
import bgImage4 from "assets/img/background-login-1.jpeg";
import TextField from '@material-ui/core/TextField';
import bgImageLogo from "assets/img/logo-react.png";
import LoadingFull from "components/Loading/LoadingFull";
import InputMask from 'react-input-mask';
import GA from 'services/GoogleAnalytics';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

//import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';

import estados from '../../services/estados.json';
import cidades_json from '../../services/cidades.json';


const bgImages = [bgImage1, bgImage2, bgImage3, bgImage4];
const imagem = bgImages[Math.floor(Math.random() * bgImages.length)];

const BlueCheckbox = withStyles({
  root: {
    color: '#03A9F4',
    padding: '0 7px 0 0',
    justifyContent: 'end',
    '&$checked': {
      color: '#03A9F4',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class CadastrarUsuario extends Component {

  constructor(props) {
    super(props);

    this.state = {
      Email: "",
      Nome: "",
      Cpf: "",
      Senha: "",
      blocking: false,
      parceiroPerfil: null,
      parceiroId: null,
      parceiroLink: null,
      uf: 'RJ',
      cidade: '',
      cidades: cidades_json.filter(cidadej => cidadej.Estado == 19),
      aceitoTermos: false
    };
    localStorage.clear();

    GA.pageview(window.location.pathname);
  }

  componentDidMount() {
    let parceiro = this.props.match.params.parceiro;
    if (parceiro != undefined) {
      axios.get(serverApi.url + '/api/v1/parceiro/Perfil/' + parceiro).then(response => {
        let parceiro = response.data;
        let perfilSistema = parceiro.PerfilSistema;
        let parceiroId = parceiro.Id;
        this.setState({ parceiroId: parceiroId });
        this.setState({ parceiroPerfil: perfilSistema });
        this.setState({ parceiroLink: parceiro.LinkParceiro });
      }).catch(error => {
        console.error(error);
      });
    }

    let queryString = this.props.location.search;
    let emailParam = queryString.split("email=")[1];
    if (emailParam != undefined) {
      this.setState({ Email: emailParam });
    }
  }

  criarUsuario = () => {

    if (!this.validateForm()) {
      return;
    }

    if (!this.state.aceitoTermos) {
      alert("É necessário aceitar os termos e condições.");
      return;
    }
    
    let THIS = this;

    this.setState({ blocking: true });

    var data = null;
    var querystring = require('querystring');
    if (this.state.parceiroId != null && this.state.parceiroPerfil) {
      data = querystring.stringify({ Email: this.state.Email, Cpf: this.state.Cpf, Telefone: this.state.Telefone, Nome: this.state.Nome, Senha: this.state.Senha, Uf: this.state.uf, Cidade: this.state.cidade, TipoPerfilParceiro: this.state.parceiroPerfil, IdParceiro: this.state.parceiroId });
    } else {
      data = querystring.stringify({ Email: this.state.Email, Cpf: this.state.Cpf, Telefone: this.state.Telefone, Nome: this.state.Nome, Senha: this.state.Senha, Uf: this.state.uf, Cidade: this.state.cidade });
    }

    axios.post(serverApi.url + serverApi.versao + '/Usuario/Cadastrar', data).then(response => {
      //axios.post(serverApi.url + '/Usuario/Cadastrar', data).then(response => {
      // handle success
      //console.log(response.data.access_token);
      alert('Sucesso!!!!\nO cadastro foi realizado com sucesso e você será direcionado para o sistema!');
      //localStorage.setItem('CLToken', response.data.access_token);
      localStorage.setItem('CLLogin', this.state.Email);
      localStorage.setItem('CLSenha', this.state.Senha);

      var querystringLogin = require('querystring');
      const dataLogin = querystringLogin.stringify({ username: this.state.Email, password: this.state.Senha, grant_type: 'password' })

      axios.post(serverApi.url + '/token', dataLogin).then(responseLogin => {
        // handle success

        localStorage.setItem('CLToken', responseLogin.data.access_token);

        THIS.props.history.push('/app/inicial');

        //console.log(responseLogin.data.access_token);
      })
        .catch(error => {
          // handle error
          console.error(error.response.data.error_description);
          try {
            switch (error.response.data.error_description) {
              case 'USUARIO_SENHA_INVALIDO':
                alert('Usuário ou senha inválidos!');
                break;
              case 'USUARIO_BLOQUEADO':
                alert('Usuário bloqueado!\nEnvie um e-mail para: sac@condutorlegal.com');
                break;
              case 'USUARIO_NAO_EXISTE':
                alert('Usuário não cadastrado em nossa base de dados!\nClique no link: primeiro acesso');
                break;
              default:
                alert('Náo foi possível realizar a autenticação!\nTente novamente!');
                break;
            }
          }
          catch (ex) {
            alert('Náo foi possível realizar a autenticação!\nTente novamente!');

          }
        })
        .finally(() => {
          this.setState({ blocking: false });
        });
    })
      .catch(error => {
        // handle error
        console.error(error.response);
        try {
          switch (error.response.data) {
            case 'SENHA_DIFERENTES':
              alert('As senhas devem ser iguais!');
              break;
            case 'EMAIL_INVALIDO':
              alert('O E-mail informado não é valido!\nVerifique se o e-mail informado está correto!');
              break;
            case 'EMAIL_JA_EXISTE':
              alert('O e-mail informado já está cadastrado em nosso sistema! Você será direcionado para a tela de login!');
              this.props.history.push('/Login');
              break;
            default:
              alert('Náo foi possível realizar o cadastro do usuário!\nTente novamente!');
              break;
          }
        }
        catch (ex) {
          alert('Náo foi possível realizar o cadastro do usuário!\nTente novamente!');
        }
        this.setState({ blocking: false });
      })
      .finally(() => {
        //this.setState({blocking: false});
      });

  };

  validateForm() {

    if (this.state.Nome.length === 0 || this.state.Email.length === 0 || this.state.Cpf.length === 0 || this.state.Senha.length === 0) {
      alert('Todos os campos devem ser preenchidos!');
      return false;
    }
    return true;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  //ESPECÍFICA PARA TELEFONA PARA LIMPAR A FORMATAÇÃO
  handleChangeTelefone = event => {
    this.setState({
      [event.target.id]: event.target.value.split("(").join("").split(")").join("").replace(" ", "").replace("-", "")
    });
  }

  //ESPECÍFICA PARA TELEFONA PARA LIMPAR A FORMATAÇÃO
  handleChangeCPF = event => {
    this.setState({
      [event.target.id]: event.target.value.replace(".", "").replace(".", "").replace("-", "")
    });
  }

  handleChangeSelectUF = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
    var estado_selecionado = event.target.value;
    var estado = estados.filter(function (item) {
      return (item.Sigla == estado_selecionado) ? item.ID : '';
    });
    let id_estado = estado[0].ID;
    var cidades_filtradas = cidades_json.filter(function (cidade) {
      return cidade.Estado == id_estado;
    });
    this.setState({ cidades: cidades_filtradas });
  };

  handleChangeSelectCidade = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleChangeTermos = event => {
    this.setState({
      aceitoTermos: event.target.checked
    });
  };

  handleSubmit = event => {
    event.preventDefault();
  }

  render() {
    return (
      <>
        <div style={{
          paddingTop: "15%", backgroundImage: "url(" + imagem + ")", position: "fixed",
          zIndex: "0",
          height: "100%",
          width: "100%",
          display: "block",
          top: "0",
          left: "0",
          backgroundSize: "cover",
          backgroundPosition: "center center", "&:after": {
            position: "absolute",
            zIndex: "3",
            width: "100%",
            height: "100%",
            content: '""',
            display: "block",
            backgroundColor: '#cccccc',
            opacity: ".8"
          }
        }}>
        </div>
        <LoadingFull display={this.state.blocking} />
        <br />
        <GridContainer>
          <GridItem xs={1} sm={2} md={3} lg={4}>
          </GridItem>
          <GridItem xs={10} sm={8} md={6} lg={4}>
            <Card>
              <CardAvatar profile>
                <img src={bgImageLogo} alt="..." style={{ width: '80px', margin: "10px" }} />
              </CardAvatar>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <div style={{ marginTop: '20px', 'width': '100%', textAlign: 'center', fontSize: '19px', fontWeight: 'bold' }}>Cadastrar Novo Usuário</div>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      style={{ marginTop: '15px' }}
                      id="Nome"
                      label="Nome Completo*"
                      margin="dense"
                      onChange={this.handleChange}
                      fullWidth
                      inputProps={{ maxLength: 150 }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      style={{ marginTop: '15px' }}
                      id="Email"
                      label="E-mail*"
                      margin="dense"
                      onChange={this.handleChange}
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                      value={this.state.Email}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <InputMask mask="999.999.999-99"
                      maskChar=" "
                      value={this.value}
                      onChange={this.handleChangeCPF}
                      required
                    >
                      {(inputProps) => <TextField {...inputProps}
                        style={{ marginTop: '15px' }}
                        id="Cpf"
                        label="CPF"
                        margin="dense"
                        fullWidth
                      />}
                    </InputMask>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <InputMask mask="(99) 9999-99999"
                      maskChar=" "
                      value={this.value}
                      onChange={this.handleChangeTelefone}>
                      {(inputProps) => <TextField {...inputProps}
                        type="tel"
                        style={{ marginTop: '15px' }}
                        id="Telefone"
                        label="Telefone*"
                        margin="dense"
                        fullWidth
                      />}
                    </InputMask>

                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      style={{ marginTop: '15px' }}
                      id="Senha"
                      label="Cadastrar Senha*"
                      margin="dense"
                      type="password"
                      onChange={this.handleChange}
                      fullWidth
                      inputProps={{ maxLength: 12 }}
                    />
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl style={{ marginTop: 26, width: '100%' }}>
                      <Select
                        native
                        id="uf"
                        value={this.state.uf}
                        onChange={this.handleChangeSelectUF}
                        required
                        inputProps={{
                          disabled: true
                        }}
                      >
                        {estados.map((currentValue, index, arr) =>
                          <option key={index} value={currentValue.Sigla} >{currentValue.Nome}</option>
                        )}
                      </Select>
                    </FormControl>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl style={{ marginTop: 26, width: '100%' }}>
                      <h4>
                        <BlueCheckbox checked={this.state.aceitoTermos} onChange={this.handleChangeTermos} />
                        <a href="https://condutorlegal.com.br/TERMOS-DE-USO-E-CONDICOES-E-POLITICAS-Versao-2-Atualizacao-20-08-22.pdf" target="_blank">{"Aceito os termos e condições"}</a>
                      </h4>
                      { this.state.parceiroLink && (this.state.parceiroLink == "motoapp" || this.state.parceiroLink == "tx") ? 
                      (<p>Programa de Afiliados - Furadinho <br/> Antes de se inscrever, leia atentamente todas as informações: <a href="https://condutorlegal.com.br/00_APRESENTACAO_AFILIADOS_FURADINHO_DIVULGACAO.pdf" target="_blank">clicando aqui</a></p>) : ("") }
                    </FormControl>
                  </GridItem>
                </GridContainer>
                {/* <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <FormControl style={{marginTop: 26, width: '100%'}}>
                            <Select
                              native
                              id="cidade"
                              value={this.state.cidade}
                              onChange={this.handleChangeSelectCidade}
                              required
                            >
                              {this.state.cidades.map((currentValue, index, arr) => 
                                <option key={index} value={currentValue.Nome} >{currentValue.Nome}</option>
                              )}
                            </Select>
                          </FormControl>
                      </GridItem>
                      </GridContainer> */}
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center", height: '55px' }}>
                    <Button onClick={this.criarUsuario} color="info">Cadastrar</Button>
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12} style={{ textAlign: "center" }}>
                    <Link to={"/Login"} >
                      Já tem cadastro? Clique aqui
                            </Link>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </>
    );
  }
}