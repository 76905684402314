import api from 'services/api.js';
import {serverApi} from 'services/constante.js';

const checaLiberacaoMenu = (props) => {
    let email_user = localStorage.getItem("CLLogin");

    var formdata = new FormData();
    formdata.append('function', 'verificacao_menus');
    formdata.append('email', email_user);

    api.post(serverApi.url + '/apicdl/usuario.php', formdata).then(response => {
        //console.log("Plano vigência", response);
        if (response.data.response == 'success') {
            if (!response.data.data.liberacao) {
                //props.history.push('/app/inicial');
                props.history.push({
                    pathname: '/app/inicial',
                    state: { message_status_menu: response.data.data.liberacao_status }
                });
                return false;
            } else {
                return true;
            }
        }
    })
    .catch(error => {
        console.error(error);
    })
    .finally(() => {  });
}

export default checaLiberacaoMenu;